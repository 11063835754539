button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.body-class {
  padding-top: 62px;
  min-height: 100vh;
}

.faq-body {
  text-align: left;
}

.faq-title {
  text-align: left;
}

.kaffeesatz {
  font-family: kaffeesatz;
}

.didact-gothic-regular {
  font-family: didact-gothic-regular;
}

.connect-banner {
  position: fixed;
  z-index: 1;
  opacity: 0.9;
  width: 100%;
  margin-top: -35px;
}

.line {
  width: 100%;
  height: 2px;
}

.line-quarter {
  width: 200px;
  margin: auto;
}

.footer {
  margin-top: 80px;
  padding-bottom: 40px;
  bottom: 0;
  width: 100%;
  z-index: -1;
  -webkit-box-shadow: 0px -4px 5px 0px rgba(148, 148, 148, 0.75) !important;
  -moz-box-shadow: 0px -4px 5px 0px rgba(158, 158, 158, 0.75) !important;
  box-shadow: 0px -4px 5px 0px rgba(163, 163, 163, 0.75) !important;
}

.card-text {
  margin: 0px;
}

.info-card {
  border-width: 0px !important;
}

.info-card-image {
  padding: 25px;
  max-width: 256px;
  max-height: 256px;
}

.product-card-image {
  margin-top: -18px;
  margin-bottom: -55px;
}

.countdown-title{
  font-size: 36px;
  font-family: kaffeesatz;
}

.countdown-number{
  font-size: 32px;
}

.countdown-text{
  font-size: 14px;
}

.countdown-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.modal-button {
  margin-top: 10px;
  margin-bottom: 0px;
}

.card-link-icon {
  text-align: right;
  padding-right: 5px;
  padding-top: 5px;
}

.modal-header {
  border-bottom: 0 none !important;
  font-family: kaffeesatz;
}

.modal-text {
  margin-top: -40px;
}

.modal-body {
  font-size: 12px;
  font-family: didact-gothic-regular;
  text-align: center;
  margin-top: -25px;
  padding-top: 0px !important;
}

.card-box-actions{
  display: flex;
  justify-content: space-between;
}

.profile-button{
  border: 0px !important;
}

.profile-modal-text {
  font-size: 14px;
  overflow-wrap: break-word;
  text-align: left;
}

.profile-modal-text-header {
  font-size: 18px;
  font-weight: bold;
  overflow-wrap: break-word;
  text-align: left;
}

.refresh-to-title {
  margin: -5px;
}

.modal-footer {
  border-bottom: 0 none !important;
}

.card-modal-detailed-information {
  font-size: 7px;
}

.navbar {
  position: relative;
}

.header {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.modal-dialog {
  outline: none !important;
}

.header-image {
  display: inline-block;
  margin: 0 auto;
}

.buy-info-parent {
  padding-top: 50px;
  padding-bottom: 10px;
}

.buy-card-image {
  margin-top: -80px;
}

.buy-info {
  text-align: left;
}

.buy-buttons {
  text-align: center;
  width: 100%;
}

.how-does-it-work-parent {
  padding-top: 50px;
  padding-bottom: 50px;
}

.in-depth-parent {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.probability-table {
  padding-bottom: 25px;
  padding-top: 10px;
}

tr:nth-child(even) {
  background-color: #ebf7ff;
}

/* EVERYTHING WITH !important GOES BELOW HERE! */

.product-card {
  padding: 3px !important;
}

.product-card-id {
  font-size: 6px !important;;
  font-family: didact-gothic-regular;
  margin: 0px !important;;
  padding: 0px !important;;
}

.product-card-title {
  font-size: 16px !important;
  font-family: didact-gothic-regular;
  font-weight: bold !important;
  margin-top: 9px !important;
  margin-bottom: 0px !important;
}

.product-card-medium {
  padding: 2px !important;
}

.product-card-medium-id {
  font-size: 5px !important;;
  font-family: didact-gothic-regular;
  margin: 0px !important;;
  padding: 0px !important;;
}

.product-card-medium-title {
  font-size: 15px !important;
  font-family: didact-gothic-regular;
  font-weight: bold !important;
  margin-top: 7px !important;
  margin-bottom: 0px !important;
}

.product-card-small {
  padding: 1px !important;
}

.product-card-title-small {
  font-size: 9px !important;
  font-family: didact-gothic-regular;
  font-weight: bold !important;
  margin-top: 7px !important;
  margin-bottom: 0px !important;
}

.product-card-id-small {
  font-size: 4px;
  font-family: didact-gothic-regular;
  margin: 0px !important;
}

.product-card-list {
  padding: 1px !important;
}

.product-card-title-list {
  font-size: 9px !important;
  font-family: didact-gothic-regular;
  font-weight: bold !important;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.product-card-id-list {
  font-size: 4px;
  font-family: didact-gothic-regular;
  margin: 0px !important;
  margin-bottom: 5px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #476072 !important;
  border-color: #476072 !important;
}

.navbar-brand-regular {
  position: absolute;
  left: 50%;
  margin-top: 35px !important;
  /* 50% of your logo width */
  margin-left: -85px !important;
  /* 50% of your logo width */
  display: block;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #042b41 !important;
}

.navbar-dark {
  color: #042b41 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #8c9eaa !important;
}

.fs-7{
  font-size: 15px !important;
}

.text-small{
  font-size: 12px !important;
}

.text-smaller{
  font-size: 10px !important;
}

.text-smallest{
  font-size: 8px !important;
}

.navbar-brand-small {
  position: absolute;
  left: 50%;
  margin-top: 35px !important;
  margin-left: -50px !important;
  display: block;
}

.dropdown-item.active, .dropdown-item:active {
  color: #000 !important;
  background-color: #FFF !important;
}

.filter-dropdown {
  width: auto !important;
  display: block !important;
}

.library-dropdowns {
  padding: 10px;
}

.library-dropdown-text {
  margin-bottom: 1px;
  padding: 2px;
  text-align: center;
}

.collected-checkbox {
  margin-right: 0px;
}

.collection-collected-x-text{
  font-size: 16px;
  font-family: didact-gothic-regular;
  margin-bottom: 0px;
}

.btn-light {
  background-color: #FFF !important;
  border-color: #ced4da !important;
}

.background1 {
  background-color: #1B262C !important;
}

.color1 {
  color: #1B262C !important;
}

.background2 {
  background-color: #0F4C75 !important;
}

.color2 {
  color: #0F4C75 !important;
}

.background3 {
  background-color: #3282B8 !important;
}

.color3 {
  color: #3282B8 !important;
}

.background4 {
  background-color: #ebf7ff !important;
}

.color4 {
  color: #BBE1FA !important;
}

.web3modal-modal-lightbox {
  z-index: 9999 !important;
}

.background5 {
  background-color: #FFFF !important;
}

.font5 {
  color: #FFFF !important;
}

.search-group {
  padding: 0px !important;
}

.page-link{
  border-color: #1B262C !important;
}

.page-item.active .page-link {
  background-color: #1B262C !important;
  border-color: #1B262C !important;
  color: #fff !important;
}