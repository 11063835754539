body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'kaffeesatz';
}

@font-face {
	font-family: kaffeesatz;
	src: url("./fonts/YanoneKaffeesatz-Regular.otf") format("opentype");
}

@font-face {
	font-family: didact-gothic-regular;
	src: url("./fonts/DidactGothic-Regular.ttf") format("truetype");
}